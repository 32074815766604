<template>
  <div class="container-fluid">
    <Header header="Dashboard" title="Home" :state="state" />

    <!-- <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <TreeView class="item" :model="treeData"/>
          </div>
        </div>
      </div>
    </div>-->
    <!-- START DASHBOARD -->
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xlg-12" v-if="detail.statusinfo == 1">
        <div class="card card-inverse card-info">
          <div class="box font-light text-white">
            <span v-html="detail.pengumuman"></span>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12 col-lg-12 col-xlg-12">
        <div class="card card-innverse card-info">
          <div class="box ">
            <h6 class="font-light text-white">Kepada Yth.</h6>
            <h6 class="font-light text-white">Bapak/Ibu Pengguna Aplikasi e-Office</h6>
            <h6 class="font-light text-white">di Tempat</h6>
            <br>
            <br>
            <h6 class="font-light text-white"></h6>
            <h6 class="font-light text-white">Bersama ini kami sampaikan bahwa Tim Teknis sedang melakukan <b>Optimalisasi Alur Disposisi
              Aplikasi e-Office</b> guna perbaikan ketidaksesuaian data disposisi yang telah masuk pada database e-office.
            <br>
            <br>
              Proses perbaikan sistem tidak akan mengganggu proses kirim dan terima surat, sehingga penggunaan aplikasi e-Office tetap dapat berjalan.
            <br>
            <br>
              Apabila memerlukan informasi lebih lanjut terkait kegiatan di atas atau terdapat kesulitannya lainnya,
              harap menghubungi admin. Mohon maaf atas ketidaknyamanan yang ditimbulkan selama proses transisi ini.
            <br>
            <br>
              Demikian disampaikan, atas perhatian dan kerjasamanya diucapkan terima kasih.</h6>
            <br>
            <br>
            <h6 class="font-light text-white">Tim Teknis e-Office</h6>
          </div>
        </div>
      </div> -->

      <!-- Surat Masuk -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_in.read"
      >
        <div class="card card-inverse card-info">
          <a v-bind:href="detail.docin_link">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">{{ detail.docin }}</h1>
              <h6 class="text-white">Surat Masuk</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Surat Masuk Belum Diproses -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_in.read"
      >
        <div class="card card-inverse card-info">
          <a v-bind:href="detail.docin_link">
            <div class="box bg-danger text-center">
              <h1 class="font-light text-white">{{ detail.docinnsuccess }}</h1>
              <h6 class="text-white">Surat Masuk Belum Diproses</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Surat Masuk Sudah Diproses -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_in.read"
      >
        <div class="card card-inverse card-info">
          <a v-bind:href="detail.docin_link">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">{{ detail.docinsuccess }}</h1>
              <h6 class="text-white">Surat Masuk Sudah Diproses</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Surat Masuk Retur -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_in.read"
      >
        <div class="card card-inverse card-info">
          <a v-bind:href="detail.docinret_link">
            <div class="box bg-dark text-center">
              <h1 class="font-light text-white">{{ detail.docinret }}</h1>
              <h6 class="text-white">Surat Masuk Retur</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Disposisi Surat -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.disposition_in.read"
      >
        <div class="card card-inverse card-warning">
          <a v-bind:href="detail.disposition_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.disposition }}</h1>
              <h6 class="text-white">Disposisi Surat</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Start Disposisi Belum Selesai -->

      <!-- <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.disposition_in.read"
      >
        <div class="card card-inverse card-danger">
          <a v-bind:href="detail.disponsuccess_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.disponsuccess }}</h1>
              <h6 class="text-white">Disposisi Belum Selesai</h6>
            </div>
          </a>
        </div>
      </div> -->

      <!-- End Disposisi Belum Selesai -->

      <!-- Start Disposisi Sudah Selesai -->

      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.disposition_in.read"
      >
        <div class="card card-innverse card-success">
          <a v-bind:href="detail.disposuccess_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.disposuccess }}</h1>
              <h6 class="text-white">Disposisi Sudah Selesai</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- End Disposisi Sudah Selesai -->

      <!-- Surat Keluar -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_out.read"
      >
        <div class="card card-primary card-inverse">
          <a v-bind:href="detail.docout_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.docout }}</h1>
              <h6 class="text-white">Surat Keluar</h6>
            </div>
          </a>
        </div>
      </div>

      <!-- Retur Surat -->
      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_out.read"
      >
        <div class="card card-inverse card-success">
          <a v-bind:href="detail.returned_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.returned }}</h1>
              <h6 class="text-white">Surat Keluar Retur</h6>
            </div>
          </a>
        </div>
      </div>

      <div
        class="col-md-6 col-lg-3 col-xlg-3"
        v-if="permissions.document_out.read"
      >
        <div class="card card-inverse card-success">
          <a v-bind:href="detail.drafting_link">
            <div class="box text-center">
              <h1 class="font-light text-white">{{ detail.drafting }}</h1>
              <h6 class="text-white">Surat Digital</h6>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Column -->
      <div class="col-12">
        <div class="card">
          <div class="row">
            <!-- <div class="col-lg-4">
              <div class="p-20">
                <h2 class="font-medium text-inverse">Aktivitas</h2>
                <div class="message-box m-t-30">
                  <div class="message-widget">
                    <a href="#" v-for="log in logs" v-bind:key="log.id">
                      <div class="user-img">
                        <span class="round">{{ log.initial }}</span>
                      </div>
                      <div class="mail-contnet">
                        <h5>{{ log.name }}</h5>
                        <span class="mail-desc">{{ log.message }}</span>
                        <span class="time">{{
                          formatDate(log.created_at)
                        }}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- Start Grafik Persuratan -->

            <!-- <div class="col-lg-8 b-l">
              <div class="card-body">
                <h4 class="font-medium text-inverse">Grafik Persuratan</h4>
                <ul class="list-inline">
                  <li class="p-l-0">
                    <h6 class="text-muted">
                      <i class="fa fa-circle m-r-5 text-success"></i>Surat Masuk
                    </h6>
                  </li>
                  <li>
                    <h6 class="text-muted">
                      <i class="fa fa-circle m-r-5 text-info"></i>Surat Keluar
                    </h6>
                  </li>
                  <li>
                    <h6 class="text-muted">
                      <i class="fa fa-circle m-r-5 text-warning"></i>Disposisi
                      Masuk
                    </h6>
                  </li>
                  <li>
                    <h6 class="text-muted">
                      <i class="fa fa-circle m-r-5 text-danger"></i>Retur Surat
                    </h6>
                  </li>
                </ul>
                <div class="total-revenue4"> -->
            <!-- <line-chart :chart-data="line"></line-chart> -->
            <!-- <bar-chart :chart-data="chartData"></bar-chart>
                </div>
              </div>
            </div> -->
            <!-- End Grafik Persuratan -->
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Top 5 Surat Instansi</h4>
            <ul class="country-state">
              <li v-for="(top, index) in topFive" v-bind:key="top.unit">
                <h2>{{ top.count }}</h2>
                <small>{{ top.unit }}</small>
                <div class="pull-right">
                  <i class="fa fa-level-up text-success"></i>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    :class="'bg-' + bg[index]"
                    role="progressbar"
                    :style="'width:' + top.percent + '%; height: 6px;'"
                    :aria-valuenow="top.percent"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Column -->
    </div>

    <!-- END DASHBOARD -->
  </div>
</template>
<script>
import axios from "axios";
import Header from "@/components/pageTitle/index";
// eslint-disable-next-line no-unused-vars
import { LineChart, BarChart } from "@/components/chart";

export default {
  components: {
    Header,
    "line-chart": LineChart,
    // "bar-chart": BarChart
  },
  mounted() {
    let vm = this;
    axios
      .get("/dashboard")
      .then((response) => {
        vm.detail = response.data;
        let responses = vm.detail.byDate;
        vm.label = responses.map((el) => {
          return el.month;
        });
        vm.datadocin = responses.map((el) => {
          return el.docin;
        });
        vm.datadocinret = responses.map((el) => {
          return el.docinret;
        });
        vm.datadocout = responses.map((el) => {
          return el.docout;
        });
        vm.datadispo = responses.map((el) => {
          return el.disposition;
        });
        vm.datareturn = responses.map((el) => {
          return el.returned;
        });
        vm.datadisponsuccess = responses.map((el) => {
          return el.disponsuccess;
        });
        vm.datadisposuccess = responses.map((el) => {
          return el.disposuccess;
        });
        vm.topFive = response.data.topFive;
        vm.logs = response.data.logs;
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
      });
  },
  data() {
    return {
      bg: ["success", "info", "inverse", "warning", "danger"],
      topFive: [],
      label: [],
      logs: [],
      datadocin: [],
      datadocinret: [],
      datadocout: [],
      datadispo: [],
      datareturn: [],
      datadisponsuccess: [],
      datadisposuccess: [],
      detail: {
        docin: 0,
        docinret: 0,
        docout: 0,
        returned: 0,
        disposition: 0,
        disponsuccess: 0,
        disposuccess: 0,
      },
      state: {
        isEdit: false,
        isAdd: false,
        isDetail: false,
      },
      myHTML: "",
      treeData: {
        name: "Boss Tobias",
        position: "Gubernur",
        status: "diBaca",
        description: "",
        children: [
          {
            name: "Jhon Doe",
            position: "Bendarhara Gubernur",
            status: "diBaca",
            description: "",
            children: [
              {
                name: "Jhane Doe",
                position: "Koordinator Humas",
                status: "diBaca",
                description: "",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    permissions() {
      if (this.$store.state.profile === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      } else if (this.$store.state.profile.permissions === undefined) {
        return {
          disposition_in: {},
          document_in: {},
          document_out: {},
        };
      }
      return this.$store.state.profile.permissions;
    },
    labels() {
      return this.label;
    },
    chartData() {
      let chartData = {
        labels: [
          "Surat Masuk",
          "Surat Keluar",
          "Disposisi Masuk",
          "Retur Surat",
        ],
        datasets: [
          {
            backgroundColor: ["#55ce63", "#009efb", "#ffbc34", "#f62d51"],
            data: [
              this.detail.docin,
              this.detail.docout,
              this.detail.disposition,
              this.detail.returned,
            ],
          },
        ],
      };
      return chartData;
    },
    line() {
      let vm = this;
      let line = {
        labels: vm.labels,
        datasets: [
          {
            label: "Surat Masuk",
            data: vm.datadocin,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent",
          },
          {
            label: "Surat Keluar",
            data: vm.datadocout,
            borderWidth: 1,
            borderColor: "#009efb",
            pointBorderColor: "#009efb",
            backgroundColor: "rgba(52,152,219,0.4)",
            pointBackgroundColor: "transparent",
          },
          {
            label: "Disposisi Masuk",
            data: vm.datadispo,
            borderWidth: 1,
            borderColor: "#ffbc34",
            pointBorderColor: "#ffbc34",
            backgroundColor: "rgba(255, 188, 52, 0.55)",
            pointBackgroundColor: "transparent",
          },
          {
            label: "Retur Surat",
            data: vm.datareturn,
            borderWidth: 1,
            borderColor: "#f62d51",
            pointBorderColor: "#f62d51",
            backgroundColor: "rgba(246, 45, 81, 0.52)",
            pointBackgroundColor: "transparent",
          },
        ],
      };

      return line;
    },
  },
};
</script>
